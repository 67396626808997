<template>

	<div id="contactos" ref="contactos" v-if="dataReady" @transition-done="prepareMap">

		<div id="photo-header" class="container-fluid d-flex align-items-center">

			<div class="container text-center">
				<h1>{{ pageinfo.titulopag }}</h1>
				<h2 class="col-12 col-md-10 offset-md-1">{{ pageinfo.subtitulopag }}</h2>
			</div>

			<img src="@/images/grunge-border-1.png" class="grunge">
		</div>

		<div class="container text-center mb50">
			<img src="@/images/textos/entre-em-contacto.svg" style="max-width:100%;" alt="Entre em contacto">
		</div>

		<div class="container mb50">

			<div class="row">
				<div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mb40">
					<h3 class="mb0">{{ pageinfo.nome }}</h3>
				</div>
			</div>

			<div class="row">

				<div class="col-12 col-md-5 offset-md-1 col-lg-4 offset-lg-2 mb30">
					<h4>Endereço:</h4>
					<p class="mb30">
						{{ pageinfo.endereco }}<br>
						{{ pageinfo.codpostal }}
						<span v-if="pageinfo.pais !== ''">, {{ pageinfo.pais }}</span><br>
					</p>

					<h4>Horário de funcionamento:</h4>
					<p v-html="pageinfo.horario"></p>
				</div>

				<div class="col-12 col-md-5 col-lg-4 mb30">
					<h4>E-mail:</h4>
					<p class="mb30">{{ pageinfo.email }} <br><br></p>

					<h4>Telefone:</h4>
					<p>
						{{ pageinfo.telefone }}<br>
					  Chamada para a rede fixa nacional
					</p>
				</div>

			</div>

		</div>

		<div class="container mb100">
			<div id="contact-map" ref="contactMap" class="moldura4"></div>
		</div>

		<MainFooter />

	</div>

</template>

<script>
/* IMPORT SCRIPTS */
import MainFooter from "@/components/footer.vue";
import globals from '../config/globals';
import {bus} from '@/main';
import axios from "axios";
import L from 'leaflet';

/* IMPORT IMAGES */
import mapMarker from '../images/map-marker.svg';
import mapMarkerShadow from '../images/map-marker-shadow.svg';


export default {
	name: "Contactos",
	components: {
		MainFooter
	},
  	data() {
		return {
			dataReady: false,
			pageinfo: null,
			map: null,
			tileLayer: null
		};
	},
	beforeCreate: function() {
        document.body.className = 'pag-contactos';
    },
	mounted () {
		axios
			.get(globals.COCKPIT_SERVER+'singletons/get/contactos'+globals.COCKPIT_TOKEN)
			.then(response => {
				this.pageinfo = response.data
				this.dataReady = true
				this.changeMetaData()
				bus.$on('transition-done', this.prepareMap)
			})
	},
	metaInfo() {
		return {
			title: 'Contactos'
		}
	},
	methods: {
		changeMetaData() {
			this.$parent.metaTitle = this.$metaInfo.title;
			this.$parent.metaDescription = this.pageinfo.subtitulopag;
		},
		prepareMap() {
			console.log('prepare map');
			this.initMap()
			this.initMarkers()
		},
		initMap() {
			this.map = L.map(this.$refs['contactMap'], { scrollWheelZoom: false }).setView([37.977356, -8.795843], 11);
			this.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/tiagorio/ckfy00xa00r0319ojzutv2e34/tiles/256/{z}/{x}/{y}?access_token={accessToken}', {
				attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
				maxZoom: 20,
				id: 'mapbox.streets',
				accessToken: 'pk.eyJ1IjoidGlhZ29yaW8iLCJhIjoiY2sxMmllMHFsMDloazNqbXFrMm90b2ZkMCJ9.FTVxuBywZ2XGF2hzZ1577w',
				scrollWheelZoom: false
			});
			this.tileLayer.addTo(this.map);

			this.myIcon = L.icon({
				iconUrl:mapMarker,
				shadowUrl:mapMarkerShadow,
				iconSize:[40,54],
				iconAnchor:[20,54],
				popupAnchor:[0,-40],
				tooltipAnchor:[16,-28],
				shadowSize:[60,54],
				shadowAnchor: [24, 54]
			});

			this.map.on('click', function() {
				if (this.scrollWheelZoom.enabled()) {
					this.scrollWheelZoom.disable();
				}
				else {
					this.scrollWheelZoom.enable();
				}
			});
		},
		initMarkers() {
			this.pageinfo.leafletObject = L.marker([this.pageinfo.mapa.lat, this.pageinfo.mapa.lng], {icon: this.myIcon}).addTo(this.map)
				.bindPopup('<b>'+this.pageinfo.nome+'</b><br><a href="http://www.google.com/maps/place/'+this.pageinfo.mapa.lat+','+this.pageinfo.mapa.lng+'" target="_blank">Abrir mapa</a>');
			this.pageinfo.leafletObject.addTo(this.map);
		}
	}
};

</script>